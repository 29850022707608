const TOS = () => {
    return (
        <div className="web-page">
            <div className="web-section all-blogs web-section-content" id="review">
                <div className="web-section__container policy-text">
                    <h1>Terms of Service</h1>
                    <p>Effective Date: 08/05/2024</p>

                    <p>Welcome to SponsorDB. By using our website and services, you agree to comply with and be bound by the following terms and conditions.</p>

                    <h3>1. Use of Our Services</h3>
                    <p>You must provide a valid email address to use our services. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You must notify us immediately of any unauthorized use of your account.</p>

                    <h3>2. User Conduct</h3>
                    <p>You agree to use our services only for lawful purposes. You must not use our services in any way that causes damage to the website or interferes with any other party's use of the website. Prohibited activities include, but are not limited to, engaging in any fraudulent behavior, spamming, or transmitting harmful code.</p>

                    <h3>3. Intellectual Property</h3>
                    <p>All content on our website, including text, graphics, logos, and software, is the property of SponsorDB and is protected by copyright laws. You may not reproduce, distribute, or create derivative works from any content without our written consent.</p>

                    <h3>4. Subscription and Payment</h3>
                    <p>Subscription fees for our services are outlined on our website. By subscribing, you agree to pay all applicable fees. All payments are processed through a third-party payment processor (e.g., Stripe) and are subject to their terms and privacy policies.</p>

                    <h3>5. Limitation of Liability</h3>
                    <p>SponsorDB will not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our services, including, but not limited to, loss of profits or data.</p>

                    <h3>6. Changes to These Terms</h3>
                    <p>We may update these Terms of Service from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the services following the posting of any changes constitutes acceptance of those changes.</p>

                    <h3>7. Governing Law</h3>
                    <p>These Terms of Service shall be governed by and construed in accordance with the laws of the State of Oklahoma, without regard to its conflict of law principles.</p>

                    <h3>8. Contact Us</h3>
                    <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:SponsorDatabase@gmail.com">SponsorDatabase@gmail.com</a>.</p>
                </div>
            </div>
        </div>
    );
}

export default TOS;